
import { debounce } from '@/utils'

const SCROLL_SIZE = 224
export default {
  name: 'CategoriesSlider',
  props: {
    totalProducts: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      pageData: {},
      scrolled: 0,
      debounceScroll: null,
      expandedDescriptionVisible: false,
      needsExpandedButton: false,
      isMounted: false,
      isSmoothScroll: false,
    }
  },
  computed: {
    pageId() {
      return this.$route.meta?.page_id || 0
    },
    showPrevious() {
      return this.scrolled > 0
    },

    showNext() {
      const { clientWidth, scrollWidth } = this.$refs?.scrollable || {
        clientWidth: 320,
        scrollWidth: 320,
      }
      if (!this.isMounted || clientWidth >= scrollWidth) return false

      return scrollWidth - clientWidth > this.scrolled
    },
  },
  created() {
    this.pageData = { ...this.$store.state?.page?.content }
    if (Array.isArray(this.pageData?.siblings))
      this.pageData.siblings = this.pageData?.siblings?.filter(
        (sibling) => sibling?.show_in_menu,
      )

    this.$store.commit('navigation/setTheme', 'default')
  },
  mounted() {
    this.$nextTick(() => {
      this.debounceScroll = debounce(this.scrollToDirection, 250)
      this.scrollToActive()
      const responsiveHeight = this.$store.getters['resize/isMobile'] ? 72 : 48
      this.needsExpandedButton =
        this.$refs.description.clientHeight > responsiveHeight
    })

    this.isMounted = true
  },
  methods: {
    isCurrentCategory(url) {
      return this.$route.path === url
    },
    scrollToDirection(direction = 'back') {
      if (direction === 'next') {
        this.scrollForward()
      } else {
        this.scrollBackward()
      }
    },
    scrollBackward() {
      this.$refs.scrollable.scrollLeft -= SCROLL_SIZE
      this.scrolled -= SCROLL_SIZE
    },
    scrollForward(scrollSize = SCROLL_SIZE) {
      this.$refs.scrollable.scrollLeft += scrollSize
      this.scrolled += scrollSize
    },
    scrollToActive() {
      if (Array.isArray(this.pageData?.siblings)) {
        let index = 0
        for (const item of this.pageData.siblings) {
          if (this.isCurrentCategory(item.processed_url)) {
            break
          }
          index++
        }
        const scrollToActivePosition = SCROLL_SIZE * (index - 1)
        const { clientWidth, scrollWidth } = this.$refs.scrollable || {
          clientWidth: 320,
          scrollWidth: 320,
        }

        const maxScrollPosition = scrollWidth - clientWidth

        if (
          scrollToActivePosition >= 0 &&
          scrollToActivePosition < maxScrollPosition
        ) {
          this.scrollForward(
            SCROLL_SIZE *
              (index -
                (this.$store.getters['resize/isTabletOrDesktop'] ? 1 : 0)),
          )
        } else if (scrollToActivePosition >= 0) {
          this.scrollForward(maxScrollPosition)
        }
        if (!this.isSmoothScroll) this.isSmoothScroll = true
      }
    },
  },
}

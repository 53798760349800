
export default {
  name: 'SelectedBadges',
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    flatFilters() {
      return (
        this.filters?.flatMap((filter) => {
          return filter.list
        }) || []
      )
    },
    hasSelectedFilters() {
      return this.flatFilters.some((filter) => filter.isSelected)
    },
  },
  methods: {
    clear() {
      this.filters.forEach((filter) => {
        filter.list.forEach((item) => {
          item.isSelected = false
        })
      })
      this.$emit('clear')
    },
  },
}


import { mapState, mapActions } from 'vuex'
export default {
  name: 'Compare',
  computed: {
    ...mapState({
      selectedForCompare: (state) => {
        return state.product.comparableProductList
      },
    }),
    compareCtaText() {
      return this.selectedForCompare.length < 2
        ? this.$t('front.product_list_page.select_more')
        : this.$t('front.product_list_page.compare_products', {
            amount:
              this.$store.getters['resize/isMobile'] &&
              this.selectedForCompare.length > 2
                ? 2
                : this.selectedForCompare.length,
            length: this.compareItemsAmount,
          })
    },
    compareCtaLink() {
      return (
        this.localePath('/compare') +
          '?productIDs=' +
          this.selectedForCompare.map((item) => item?.id).join() || ''
      )
    },
    compareItemsAmount() {
      return this.$store.getters['resize/isMobile'] ? 2 : 4
    },
    isCompareValid() {
      return this.selectedForCompare.length >= 2
    },
  },
  destroyed() {
    this.resetComparableProductList()
  },
  mounted() {
    this.loadComparableProducts()
  },
  methods: {
    ...mapActions({
      resetComparableProductList: 'product/resetComparableProductList',
      toggleComparableProduct: 'product/toggleComparableProduct',
      loadComparableProducts: 'product/loadComparableProducts',
    }),
  },
}

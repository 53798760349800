
export default {
  name: 'PortfolioCategory',
  props: {
    media: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
}
